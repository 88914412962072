import React from "react";
import {AxiosError} from "axios";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {QueryClient} from "@tanstack/react-query";
import {AuthContextProps, useAuth} from "react-oidc-context";
import * as yup from "yup";
import Loading from "../../components/Loading";
import {useForm} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import {yupResolver} from "@hookform/resolvers/yup";
import Title from "../../components/Title";
import {
    Button, CardActions, Box, CardContent,
    Step, StepLabel, Stepper, Grid, Card, Container
} from "@mui/material";

import {ISaveProductForm} from "./ISaveProductForm";
import {Assembly, IFormInputProduct, VersionLot} from "../../types/index";
import productService, {ISavingProduct} from "../../services/ProductService";
import {Step1IdentificationContainer} from "./container/Step1IdentificationContainer";
import {Step2BddContainer} from "./container/Step2BddContainer";
import {Step3KeycloakContainer} from "./container/Step3KeycloakContainer";
import {Step4ModuleContainer} from "./container/Step4ModuleContainer";

interface IProductServiceReturn {
    isLoading?: boolean;
    error?: any;
    data?: Assembly;
}

interface KeycloakProviderConfig {
    authorizationUrl: string;
    tokenUrl: string;
    clientId: string;
    clientSecret: string;
    logoutUrl?: string;
    userInfoUrl?: string;
    issuer?: string;
    validateSignature: boolean;
    useJwksUrl: boolean;
    jwksUrl?: string;
    usePkce: boolean;
    pkceMethod: string;
    clientAuthMethod: string;
    syncMode: string;
}

interface KeycloakDatabase {
    hostname: string,
    port: number,
    username: string,
    password: string,
    name: string;
}

interface IDefaultValues {
    name: string,
    numeroVersion: string,
    numeroVersionTemplate: string,
    serverAlias: string,
    deploy: boolean,
    database: {
        demoDb: boolean,
        hostname?: string,
        port?: string,
        username?: string,
        password?: string,
        name?: string
    },
    keycloak?: {
        keycloakMode: string,
        adminUsername: string,
        adminPassword: string,
        redirectUri: string,
        keycloakProviderConfig?: KeycloakProviderConfig,
        keycloakDatabase?: KeycloakDatabase
    },
    defaultLanguage: string,
    defaultCurrency: string,
    resources: {
        requestsMemory: number,
        limitsMemory: number,
        requestsCpu: number,
        limitsCpu: number,
    }
}

export const SaveProductPage: React.FC = () => {
    const auth: AuthContextProps = useAuth();
    const queryClient: QueryClient = new QueryClient();
    const navigate: NavigateFunction = useNavigate();

    const {step} = useParams<"step">();
    const {updateId} = useParams<"updateId">();

    let iProductServiceReturn: IProductServiceReturn = productService.getProductById(auth, updateId);

    const [versionList, setVersionList] = React.useState(() => {
        const result: VersionLot[] = [];
        return result;
    });

    const steps = [
        'Identification',
        'Base de données',
        'Keycloak',
        'Modules',
    ];

    const updateVersionLotChoose = (versionList: VersionLot[]) => {
        setVersionList(versionList);
    }

    let defaultValues: IDefaultValues = {
        name: "",
        serverAlias: "",
        numeroVersion: "1.0.0",
        numeroVersionTemplate: "",
        deploy: false,
        database: {
            demoDb: false,
            hostname: "",
            port: "",
            username: "",
            password: "",
            name: ""
        },
        keycloak: {
            keycloakMode: "DEMO",
            adminPassword: "",
            adminUsername: "",
            redirectUri: "",
            keycloakProviderConfig: undefined,
            keycloakDatabase: undefined,
        },
        defaultLanguage: "fr-FR",
        defaultCurrency: "EUR",
        resources: {
            requestsMemory: 256,
            limitsMemory: 768,
            requestsCpu: 200,
            limitsCpu: 1000,
        }
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required("name is a required field"),
        serverAlias: yup.string(),
        database: yup.object().shape({
            demoDb: yup.boolean(),
            hostname: yup.string().when('demoDb', {
                is: false,
                then(schema) {
                    return schema.required('Db hostname is required');
                },
            }),
            port: yup.number().nullable(),
            username: yup.string().when('demoDb', {
                is: false,
                then(schema) {
                    return schema.required('Db username is required');
                },
            }),
            password: yup.string().when('demoDb', {
                is: (demoDb:boolean) => !demoDb && updateId==undefined,
                then(schema) {
                    return schema.required('Db password is required');
                },
            }),
            name: yup.string().when('demoDb', {
                is: false,
                then(schema) {
                    return schema.required('Db name is required');
                },
            }),
        }),
        keycloak: yup.object().shape({
            keycloakMode: yup.string().required("Keycloak mode is required"),
            adminUsername: yup.string().required("username is a required field"),
            adminPassword: yup.string().required("password is a required field")
        }),
        defaultLanguage: yup.string().required("defaultLanguage is a required field"),
        defaultCurrency: yup.string().required("defaultCurrency is a required field"),
        resources: yup.object().shape({
            requestsMemory: yup.number().nonNullable(),
            limitsMemory: yup.number().nonNullable(),
            requestsCpu: yup.number().nonNullable(),
            limitsCpu: yup.number().nonNullable(),
        }),
    });

    const chooseStep = (index: number) => {
        if (index === 1) {
            return <Step1IdentificationContainer
                control={iSaveProductForm.control}
                setValue={iSaveProductForm.setValue}/>;
        } else if (index === 2) {
            return <Step2BddContainer
                control={iSaveProductForm.control}
                watch={iSaveProductForm.watch}
                updateId={updateId}
            />;
        } else if (index === 3) {
            return <Step3KeycloakContainer
                control={iSaveProductForm.control}
                watch={iSaveProductForm.watch}
                updateId={updateId}
            />;
        } else if (index === 4) {
            return <Step4ModuleContainer auth={auth} updateId={updateId} versionLots={versionList}
                                         updateVersionLotChoose={updateVersionLotChoose}/>;
        }

        return <p>Erreur</p>;
    }

    const iSaveProductForm: ISaveProductForm = useForm<any>({
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema),
    });

    React.useEffect(() => {
        if (iProductServiceReturn.data && iProductServiceReturn.data.assemblyDatabase && iProductServiceReturn.data.assemblyKeycloak) {
            defaultValues = {
                name: iProductServiceReturn.data.name,
                serverAlias: iProductServiceReturn.data.serverAlias,
                numeroVersion: iProductServiceReturn.data.numeroVersion,
                numeroVersionTemplate: iProductServiceReturn.data.numeroVersionTemplate,
                deploy: iProductServiceReturn.data.deploy,
                database: {
                    demoDb: iProductServiceReturn.data.assemblyDatabase?.demoDb,
                    hostname: iProductServiceReturn.data.assemblyDatabase?.hostname,
                    port: iProductServiceReturn.data.assemblyDatabase?.port,
                    username: iProductServiceReturn.data.assemblyDatabase?.username,
                    password: "",
                    name: iProductServiceReturn.data.assemblyDatabase?.name
                },
                keycloak: {
                    keycloakMode: iProductServiceReturn.data.assemblyKeycloak.keycloakMode,
                    adminUsername: iProductServiceReturn.data.assemblyKeycloak.adminUsername,
                    adminPassword: iProductServiceReturn.data.assemblyKeycloak.adminPassword,
                    redirectUri: iProductServiceReturn.data.assemblyKeycloak.redirectUri,
                    keycloakProviderConfig :iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig?{
                        authorizationUrl: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.authorizationUrl,
                        tokenUrl: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.tokenUrl,
                        clientId: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.clientId,
                        clientSecret: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.clientSecret,
                        logoutUrl: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.logoutUrl,
                        userInfoUrl: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.userInfoUrl,
                        issuer: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.issuer,
                        validateSignature: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.validateSignature as boolean,
                        useJwksUrl: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.useJwksUrl as boolean,
                        jwksUrl: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.jwksUrl,
                        usePkce: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.usePkce,
                        pkceMethod: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.pkceMethod,
                        clientAuthMethod: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.clientAuthMethod,
                        syncMode: iProductServiceReturn.data.assemblyKeycloak.keycloakProviderConfig.syncMode
                    } : undefined,
                    keycloakDatabase: iProductServiceReturn.data.assemblyKeycloak.keycloakDatabase?{
                        hostname: iProductServiceReturn.data.assemblyKeycloak.keycloakDatabase.hostname,
                        port: iProductServiceReturn.data.assemblyKeycloak.keycloakDatabase.port,
                        username: iProductServiceReturn.data.assemblyKeycloak.keycloakDatabase.username,
                        password: iProductServiceReturn.data.assemblyKeycloak.keycloakDatabase.password,
                        name: iProductServiceReturn.data.assemblyKeycloak.keycloakDatabase.name
                    } : undefined
                },
                defaultLanguage: "FR",
                defaultCurrency: "EUR",
                resources: {
                    requestsMemory: iProductServiceReturn.data.assemblyResources.requestsMemory,
                    limitsMemory: iProductServiceReturn.data.assemblyResources.limitsMemory,
                    requestsCpu: iProductServiceReturn.data.assemblyResources.requestsCpu,
                    limitsCpu: iProductServiceReturn.data.assemblyResources.limitsCpu,
                }
            };

            setVersionList(iProductServiceReturn.data.versionLots);
        }
        iSaveProductForm.reset(defaultValues);


    }, [iProductServiceReturn.data]);

    const urlGenerate = (index: number): string => {

        if (updateId) {
            return `/product/${updateId}/step/${index}`;
        } else {
            return `/create/step/${index}`;
        }
    }

    const messageError = (errorCreating: any) => {
        if (errorCreating instanceof AxiosError) {
            return (
                <p>Create error : {errorCreating.response?.data}</p>
            );
        } else {
            return (
                <p>Create error : {errorCreating.message}</p>
            );
        }
    };

    const onSubmit = (iFormInputProduct: IFormInputProduct) => {
        iFormInputProduct.versionList = versionList;
        console.log(onSubmit);
        console.log(iFormInputProduct);


        if (updateId) {
            putProduct.SaveProduct(iFormInputProduct);
        } else {
            postProduct.SaveProduct(iFormInputProduct);
        }
    }

    const postProduct: ISavingProduct = productService.usePostProduct(auth, queryClient, navigate);

    const putProduct: ISavingProduct = productService.usePutProduct(auth, updateId, queryClient, navigate);

    if (updateId && iProductServiceReturn.isLoading) {
        return (
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{my: 4}}>
                            <Loading/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        )
    } else {
        return (
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{my: 4}}>
                            <Card sx={{minWidth: 275}}>
                                <CardContent>
                                    <Title>
                                        {!updateId && 'Nouveau produit'}
                                        {updateId && 'Modification produit'}
                                    </Title>

                                    <Stepper activeStep={Number(step) - 1} alternativeLabel>
                                        {steps.map((label: string) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>

                                    {chooseStep(Number(step))}

                                </CardContent>
                                <CardActions>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
                                        <Grid item xs={12} md={12}>
                                            {postProduct.isErrorSaving && messageError(postProduct.errorSaving)}
                                            {putProduct.isErrorSaving && messageError(putProduct.errorSaving)}
                                            {(postProduct.isSaving || putProduct.isSaving) && <p>Work..</p>}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button component={RouterLink} to={urlGenerate(Number(step) - 1)}
                                                    disabled={Number(step) - 1 <= 0}>
                                                Retour
                                            </Button>
                                        </Grid>
                                        <Grid container item xs={12} md={6} justifyContent="flex-end">

                                            {Number(step) + 1 > 4 ? (
                                                <Button onClick={iSaveProductForm.handleSubmit(onSubmit)}
                                                        variant={"contained"}>
                                                    {!updateId && 'Créer'}
                                                    {updateId && 'Modifier'}
                                                </Button>
                                            ) : (
                                                <Button component={RouterLink} to={urlGenerate(Number(step) + 1)}>
                                                    Suivant
                                                </Button>
                                            )}

                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                            <DevTool control={iSaveProductForm.control}/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}